import { useLDClient, withLDProvider } from "launchdarkly-react-client-sdk";
import * as React from "react";
import { auth } from "@src/firebaseConfig";
import SignIn from "./auth/SignIn";
import { signOut } from "./auth/utils";
import { onAuthStateChanged } from "firebase/auth";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { bugsnagClient } from "./bugsnag";
import SitesContext, { SiteContext } from "./context/SitesContext";
import UserContext from "./context/UserContext";
import DebugBanner from "./DebugBanner";
import {
  isDev,
  VITE_DISABLE_SUPER_ADMIN_IN_CLIENT,
  VITE_LD_CLIENT_ID,
  VITE_MOCK_SERVICE_WORKER,
} from "./env";
import { useFeatureFlag } from "./featureFlags";
import { Loader } from "./global_components";
import { queryCurrentUser } from "./global_functions/postgrestApi";
import {
  handleGetSites,
  handleRefreshSite,
  SITE_SUMMARY_FIELDS,
} from "./global_functions/sites/sitesHelpers";
import useAsync from "./global_functions/useAsync";
import LiveSession from "./livesession";
import PortalRoot from "./PortalRoot";
import { BackpackTelemetryManager } from "./telemetry";
import type { SummaryOverview } from "./types";
import { useQuery, useQueryClient } from "react-query";
const BackpackConsoleRouter = React.lazy(
  () => import("./backpack-console/Router")
);
const consoleUnauthenticatedBaseRoutes = [
  "/rewards",
  "/print", // PDF service needs to access app
  "/auth/action",
  "/accept-invite",
];

const checkUnauthenticatedRoute = (pathname: string) => {
  return consoleUnauthenticatedBaseRoutes.some((route) =>
    pathname.startsWith(route)
  );
};

function AppContent() {
  const location = useLocation();
  const ldClient = useLDClient();
  const [showLoginPage, setShowLoginPage] = React.useState(false);
  const [isAuthStateReady, setIsAuthStateReady] = React.useState(false);
  const [isCurrentUserError, setIsCurrentUserError] = React.useState(false);

  const isUnauthenticatedRoute = checkUnauthenticatedRoute(location.pathname);

  const [currentUser, refreshCurrentUser] = useAsync(async () => {
    if (!ldClient) return;

    if (VITE_MOCK_SERVICE_WORKER) {
      await import("./__mocks__/msw/browser").then(({ worker }) => {
        worker.start({
          onUnhandledRequest: "bypass",
        });
      });
    }

    await auth.authStateReady();
    setTimeout(() => setIsAuthStateReady(true));

    // get the current Google Identity Platform user
    const { currentUser: authUser } = auth;
    if (!authUser && !isUnauthenticatedRoute) {
      setShowLoginPage(true);
      return;
    }

    // query the Backpack user with the valid JWT from Google Identity Platform
    const currUser = await queryCurrentUser();

    // if we don't have the current user we can't do anything in the app
    if (!currUser && !isUnauthenticatedRoute) {
      signOut();
      setIsCurrentUserError(true);
    } else if (currUser) {
      setIsCurrentUserError(false);
      LiveSession.identifyUser({
        email: currUser.email,
        name: `${currUser.firstName ?? ""} ${currUser.lastName ?? ""}`,
        accountId: `${currUser.accountId ?? ""}`,
        userId: currUser.id,
      });

      ldClient.identify({
        kind: "user",
        key: currUser.id.toString(),
        firstName: currUser.firstName ?? "N/A",
        lastName: currUser.lastName ?? "N/A",
        email: currUser.email,
        avatar: currUser.imageURL ?? "N/A",
        accountId: currUser.accountId ?? "N/A",
        superAdmin: currUser.superAdmin,
        permissions: currUser.userPermissions,
        userRole: currUser.userRole ?? "N/A",
        title: currUser.title ?? "N/A",
      });

      bugsnagClient?.setUser(
        String(currUser.id),
        currUser.email,
        `${currUser.firstName ?? ""} ${currUser.lastName ?? ""}`
      );

      bugsnagClient?.addMetadata("user", {
        accountId: currUser.accountId,
        superAdmin: currUser.superAdmin,
        permissions: currUser.userPermissions,
        userRole: currUser.userRole ?? "N/A",
        title: currUser.title ?? "N/A",
      });

      bugsnagClient?.addFeatureFlags(
        Object.entries(ldClient.allFlags()).map(([name, variant]) => ({
          name,
          variant: variant !== null ? String(variant) : null,
        }))
      );

      const devDisableSuperAdmin =
        isDev && currUser.superAdmin && VITE_DISABLE_SUPER_ADMIN_IN_CLIENT
          ? { superAdmin: false }
          : {};

      return isDev
        ? {
            ...currUser,
            ...devDisableSuperAdmin,
          }
        : currUser;
    }
  }, [isUnauthenticatedRoute, ldClient]);

  const userContextValue = React.useMemo(
    () => ({
      currentUser: currentUser.data,
      refreshCurrentUser,
    }),
    [currentUser, refreshCurrentUser]
  );

  React.useEffect(() => {
    refreshCurrentUser();
    const unsubscribe = onAuthStateChanged(auth, () => {
      refreshCurrentUser();
    });

    return () => unsubscribe();
  }, [refreshCurrentUser]);

  const currentUserExists = Boolean(currentUser.data);

  React.useEffect(() => {
    if (isUnauthenticatedRoute || currentUserExists) {
      setShowLoginPage(false);
    }
  }, [currentUserExists, isUnauthenticatedRoute]);

  if (!isAuthStateReady) return <Loader size={80} />;

  return (
    <>
      {showLoginPage && <SignIn isCurrentUserError={isCurrentUserError} />}
      {!showLoginPage && (
        <UserContext.Provider value={userContextValue}>
          <SitesContextProvider
            isSuperAdmin={userContextValue.currentUser?.superAdmin}
            currentUserExists={currentUserExists}
          >
            <BackpackTelemetryManager />
            <PortalRoot />
            <DebugBanner />
            <React.Suspense fallback={<Loader size={80} />}>
              <BackpackConsoleRouter currentUserExists={currentUserExists} />
            </React.Suspense>
          </SitesContextProvider>
        </UserContext.Provider>
      )}
    </>
  );
}

function AppWrapper() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export function SitesContextProvider({
  children,
  isSuperAdmin,
  currentUserExists,
}: {
  isSuperAdmin?: boolean;
  currentUserExists: boolean;
  children: React.ReactNode;
}) {
  const isInactiveSitesEnabled =
    useFeatureFlag("inactive-sites", "off") === "on" && isSuperAdmin;

  const { data: sites, isLoading } = useQuery(
    ["summary-overview", isInactiveSitesEnabled],
    () => handleGetSites(isInactiveSitesEnabled, SITE_SUMMARY_FIELDS),
    { enabled: currentUserExists, refetchOnWindowFocus: false }
  );

  const queryClient = useQueryClient();

  const setSites = React.useCallback(
    (cb: (prev?: SummaryOverview[]) => SummaryOverview[]) =>
      queryClient.setQueryData<SummaryOverview[]>(
        ["summary-overview", isInactiveSitesEnabled],
        cb
      ),
    [queryClient, isInactiveSitesEnabled]
  );

  const [refreshSiteState, refreshSite] = useAsync(
    async (siteId: string | number) =>
      handleRefreshSite(
        siteId,
        setSites,
        isInactiveSitesEnabled,
        SITE_SUMMARY_FIELDS
      ),
    [setSites, isInactiveSitesEnabled]
  );

  const siteContextValue: SiteContext = React.useMemo(
    () => ({
      activeSites: sites?.filter(({ status }) => status !== "inactive") || [],
      allSites: sites || [],
      refreshSite,
      refreshSiteState,
      loading: isLoading,
    }),
    [sites, refreshSite, refreshSiteState, isLoading]
  );

  return (
    <SitesContext.Provider value={siteContextValue}>
      {children}
    </SitesContext.Provider>
  );
}

export default withLDProvider({
  clientSideID: VITE_LD_CLIENT_ID,
  // initialize app with anonymous user context to prevent blowing through our LD Monthly Active User limit
  context: { kind: "user", key: "uninitialized-user", anonymous: true },
})(AppWrapper as React.ComponentType<{}>);
